@import 'color-variables.css';

/* --- TEXT ---  */
/* #region */
.txt-brand-white {
	color: var(--brand-white) !important;
}
.txt-g-1 {
	color: var(--gray-1) !important;
}
.txt-g {
	color: var(--gray) !important;
}
.txt-g1 {
	color: var(--gray1) !important;
}
.txt-g2 {
	color: var(--gray2) !important;
}

.txt-black {
	color: var(--black) !important;
}
.txt-dark-black {
	color: var(--black-dark) !important;
}
.txt-darker-black {
	color: var(--black-darker) !important;
}

.txt-brand-2 {
	color: var(--brand-2) !important;
}
.txt-brand {
	color: var(--brand) !important;
}
.txt-brand2 {
	color: var(--brand2) !important;
}

.txt-brand-accent {
	color: var(--brand-accent) !important;
}

.txt-danger {
	color: var(--red) !important;
}

.txt-success {
	color: var(--success) !important;
}
.txt-info {
	color: var(--info) !important;
}

.dark-text {
	color: var(--black) !important;
}
.brand-text {
	color: var(--brand);
}

.gray-text {
	color: var(--gray) !important;
}
.lg-text {
	color: var(--light-gray);
}
.text-danger {
	color: var(--danger);
}
.txt-alternate1 {
	color: var(--alternate1-dark);
}
.txt-alternate2 {
	color: var(--alternate2-dark);
}
/* #endregion */

/* #region */
.fw-500 {
	font-weight: 500;
}
/* #endregion */

/* #region */
.text-strikethrough {
	text-decoration: line-through;
}
/* #endregion */
