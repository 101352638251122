/*=======================
       REACT DATE PICKER
=========================*/
/*#region*/
.react-date-picker__wrapper,
input.react-date-picker__inputGroup__input {
	border: none !important;
}

button.react-date-picker__calendar-button.react-date-picker__button:focus {
	background-color: var(--brand);
}

.react-date-picker__button {
	padding: 4px 6px 10px !important;
}

.react-date-picker__inputGroup__divider {
	padding: 1px 5px 0 0 !important;
}

.react-calendar__navigation {
	margin: 0 !important;
}

.react-calendar__month-view__weekdays__weekday > abbr {
	text-decoration: none;
	font-weight: 800;
}
/* #endregion */

/* CALENDAR DATE PICKER */
.datepicker-date-display {
	background-color: var(--brand1) !important;
}

.datepicker-table td.is-selected {
	background-color: var(--brand-accent) !important;
}
.datepicker-table td.is-selected > button.datepicker-day-button {
	font-weight: bold;
}

.datepicker-day-button:focus {
	background-color: rgba(59, 118, 239, 0.25) !important;
}
.datepicker-table td.is-today {
	color: var(--brand-accent) !important;
}
.datepicker-table td.is-today.is-selected {
	color: var(--brand-white) !important;
}
.datepicker-table td.is-today > button.datepicker-day-button {
	font-weight: bold;
}

.datepicker-cancel,
.datepicker-clear,
.datepicker-today,
.datepicker-done {
	color: var(--brand) !important;
}

.date-text::after {
	content: 'OJO: Para cambiar el año debes seleccionar el año de límite inferior. Cuando vuelvas a abrir la lista veras más años a elegir. Repite los pasos hasta llegar al año que buscas.';
	color: var(--gray);
	font-size: small;
	line-height: 1.25rem;
	display: block;
	margin-top: 10rem;
}
