i.small {
	font-size: 2rem !important;
}

.dropdown-content li > a,
.dropdown-content li > span {
	color: var(--brand-accent) !important;
}
.select-wrapper input.select-dropdown:focus {
	border-bottom: 1px solid var(--brand) !important;
	box-shadow: 0 1px 0 0 var(--brand) !important;
}
.select-wrapper
	input.select-dropdown:focus
	.select-dropdown.dropdown-content
	li:hover {
	background-color: var(--lighter-shade) !important;
}
.select-dropdown.dropdown-content li.selected {
	background-color: var(--main-bg) !important;
}
.card .card-title {
	font-size: calc(10px + 1vmax);
}

.chip {
	color: var(--gray1) !important;
	background-color: var(--gray-1) !important;
}

input:not([type]):focus:not([readonly]),
input[type='text']:not(.browser-default):focus:not([readonly]),
input[type='password']:not(.browser-default):focus:not([readonly]),
input[type='email']:not(.browser-default):focus:not([readonly]),
input[type='url']:not(.browser-default):focus:not([readonly]),
input[type='time']:not(.browser-default):focus:not([readonly]),
input[type='date']:not(.browser-default):focus:not([readonly]),
input[type='datetime']:not(.browser-default):focus:not([readonly]),
input[type='datetime-local']:not(.browser-default):focus:not([readonly]),
input[type='tel']:not(.browser-default):focus:not([readonly]),
input[type='number']:not(.browser-default):focus:not([readonly]),
input[type='search']:not(.browser-default):focus:not([readonly]),
textarea.materialize-textarea:focus:not([readonly]) {
	border-bottom: 1px solid var(--brand) !important;
	box-shadow: 0 1px 0 0 var(--brand) !important;
}

input:not([type]):focus:not([readonly]),
input[type='text']:not(.browser-default):focus:not([readonly]),
input[type='password']:not(.browser-default):focus:not([readonly]),
input[type='email']:not(.browser-default):focus:not([readonly]),
input[type='url']:not(.browser-default):focus:not([readonly]),
input[type='time']:not(.browser-default):focus:not([readonly]),
input[type='date']:not(.browser-default):focus:not([readonly]),
input[type='datetime']:not(.browser-default):focus:not([readonly]),
input[type='datetime-local']:not(.browser-default):focus:not([readonly]),
input[type='tel']:not(.browser-default):focus:not([readonly]),
input[type='number']:not(.browser-default):focus:not([readonly]),
input[type='search']:not(.browser-default):focus:not([readonly]),
textarea.materialize-textarea:focus:not([readonly]) {
	border-bottom: 1px solid var(--brand) !important;
	-webkit-box-shadow: 0 1px 0 0 var(--brand) !important;
	box-shadow: 0 1px 0 0 var(--brand) !important;
}

/* //~ INVALID FIELDS */
/* #region */
input[type='text']:not(.browser-default):focus:not([readonly]):invalid,
input[type='password']:not(.browser-default):focus:not([readonly]):invalid,
input[type='email']:not(.browser-default):focus:not([readonly]):invalid,
input[type='url']:not(.browser-default):focus:not([readonly]):invalid,
input[type='time']:not(.browser-default):focus:not([readonly]):invalid,
input[type='date']:not(.browser-default):focus:not([readonly]):invalid,
input[type='datetime']:not(.browser-default):focus:not([readonly]):invalid,
input[type='datetime-local']:not(.browser-default):focus:not([readonly]):invalid,
input[type='tel']:not(.browser-default):focus:not([readonly]):invalid,
input[type='number']:not(.browser-default):focus:not([readonly]):invalid,
input[type='search']:not(.browser-default):focus:not([readonly]):invalid,
textarea.materialize-textarea:focus:not([readonly]):invalid {
	border-bottom: 1px solid var(--danger) !important;
	box-shadow: 0 1px 0 0 var(--danger) !important;
}

input[type='text']:not(.browser-default):not([readonly]):invalid
	+ span.invalid::after,
/* input[type='password']:not(.browser-default):not([readonly]):invalid
	+ span.invalid::after, */
input[type='email']:not(.browser-default):not([readonly]):invalid
	+ span.invalid::after,
input[type='url']:not(.browser-default):not([readonly]):invalid
	+ span.invalid::after,
input[type='time']:not(.browser-default):not([readonly]):invalid
	+ span.invalid::after,
input[type='date']:not(.browser-default):not([readonly]):invalid
	+ span.invalid::after,
input[type='datetime']:not(.browser-default):not([readonly]):invalid
	+ span.invalid::after,
input[type='datetime-local']:not(.browser-default):not([readonly]):invalid
	+ span.invalid::after,
input[type='tel']:not(.browser-default):not([readonly]):invalid
	+ span.invalid::after,
input[type='number']:not(.browser-default):not([readonly]):invalid
	+ span.invalid::after,
input[type='search']:not(.browser-default):not([readonly]):invalid
	+ span.invalid::after,
textarea.materialize-textarea:not([readonly]):invalid + span.invalid::after {
	content: 'X' !important;
	color: transparent;
	font-size: large;
	font-weight: 600;
	position: absolute;
	right: 12px;
	top: 12px;
}
input[type='text']:not(.browser-default):focus:not([readonly]):invalid
	+ span::after,
/* input[type='password']:not(.browser-default):focus:not([readonly]):invalid
	+ span::after, */
input[type='email']:not(.browser-default):focus:not([readonly]):invalid
	+ span::after,
input[type='url']:not(.browser-default):focus:not([readonly]):invalid
	+ span::after,
input[type='time']:not(.browser-default):focus:not([readonly]):invalid
	+ span::after,
input[type='date']:not(.browser-default):focus:not([readonly]):invalid
	+ span::after,
input[type='datetime']:not(.browser-default):focus:not([readonly]):invalid
	+ span::after,
input[type='datetime-local']:not(.browser-default):focus:not([readonly]):invalid
	+ span::after,
input[type='tel']:not(.browser-default):focus:not([readonly]):invalid
	+ span::after,
input[type='number']:not(.browser-default):focus:not([readonly]):invalid
	+ span::after,
input[type='search']:not(.browser-default):focus:not([readonly]):invalid
	+ span::after,
textarea.materialize-textarea:focus:not([readonly]):invalid + span::after {
	color: var(--danger);
}
input[type='text']:not(.browser-default):not([readonly]):valid + span::after,
/* input[type='password']:not(.browser-default):not([readonly]):valid
	+ span::after, */
input[type='email']:not(.browser-default):not([readonly]):valid + span::after,
input[type='url']:not(.browser-default):not([readonly]):valid + span::after,
input[type='time']:not(.browser-default):not([readonly]):valid + span::after,
input[type='date']:not(.browser-default):not([readonly]):valid + span::after,
input[type='datetime']:not(.browser-default):not([readonly]):valid
	+ span::after,
input[type='datetime-local']:not(.browser-default):not([readonly]):valid
	+ span::after,
input[type='tel']:not(.browser-default):not([readonly]):valid + span::after,
input[type='number']:not(.browser-default):not([readonly]):valid + span::after,
input[type='search']:not(.browser-default):not([readonly]):valid + span::after,
textarea.materialize-textarea:not([readonly]):valid + span::after {
	/* content: 'X'; */
	content: '\2713';
	color: var(--success);
	font-size: large;
	font-weight: 600;
	position: absolute;
	right: 12px;
	top: 12px;
}

input:not(.browser-default):focus:not([readonly]):invalid + span + sup {
	color: var(--danger);
}

.invalid-text {
	font-size: 0.88rem;
	text-align: right;
	color: var(--danger);
	margin: 0 0 0 3.5rem !important;
}

input[type='text']:not(.browser-default):not([readonly]) + i.material-icons,
input[type='password']:not(.browser-default):not([readonly])
	+ i.material-icons {
	color: #9e9e9e;
	cursor: pointer;
	font-size: large;
	font-weight: 600;
	position: absolute;
	right: 12px;
	top: 12px;
}
/* input[type='text']:not(.browser-default):not([readonly]):invalid
	+ i.material-icons,
input[type='password']:not(.browser-default):not([readonly]):invalid
	+ i.material-icons {
	color: #9e9e9e;
	cursor: pointer;
	font-size: large;
	font-weight: 600;
	position: absolute;
	right: 12px;
	top: 12px;
} */

input[type='text']:not(.browser-default):not([readonly]):valid ~ span::after,
input[type='password']:not(.browser-default):not([readonly]):valid
	~ span::after {
	content: '\2713';
	color: var(--success);
	font-size: large;
	font-weight: 600;
	position: absolute;
	right: 12px;
	top: 12px;
}

input[type='text']:not(.browser-default):not([readonly]):invalid
	+ i.material-icons:hover,
input[type='password']:not(.browser-default):not([readonly]):invalid
	+ i.material-icons:hover {
	color: var(--brand);
}

/* #endregion */

input:not([type]):focus:not([readonly]) + label,
input[type='text']:not(.browser-default):focus:not([readonly]) + label,
input[type='password']:not(.browser-default):focus:not([readonly]) + label,
input[type='email']:not(.browser-default):focus:not([readonly]) + label,
input[type='url']:not(.browser-default):focus:not([readonly]) + label,
input[type='time']:not(.browser-default):focus:not([readonly]) + label,
input[type='date']:not(.browser-default):focus:not([readonly]) + label,
input[type='datetime']:not(.browser-default):focus:not([readonly]) + label,
input[type='datetime-local']:not(.browser-default):focus:not([readonly])
	+ label,
input[type='tel']:not(.browser-default):focus:not([readonly]) + label,
input[type='number']:not(.browser-default):focus:not([readonly]) + label,
input[type='search']:not(.browser-default):focus:not([readonly]) + label,
textarea.materialize-textarea:focus:not([readonly]) + label {
	color: var(--brand) !important;
}

input:not([type]):focus:not([readonly]) + label,
input[type='text']:not(.browser-default):focus:not([readonly]) + label,
input[type='password']:not(.browser-default):focus:not([readonly]) + label,
input[type='email']:not(.browser-default):focus:not([readonly]) + label,
input[type='url']:not(.browser-default):focus:not([readonly]) + label,
input[type='time']:not(.browser-default):focus:not([readonly]) + label,
input[type='date']:not(.browser-default):focus:not([readonly]) + label,
input[type='datetime']:not(.browser-default):focus:not([readonly]) + label,
input[type='datetime-local']:not(.browser-default):focus:not([readonly])
	+ label,
input[type='tel']:not(.browser-default):focus:not([readonly]) + label,
input[type='number']:not(.browser-default):focus:not([readonly]) + label,
input[type='search']:not(.browser-default):focus:not([readonly]) + label,
textarea.materialize-textarea:focus:not([readonly]) + label {
	color: var(--brand) !important;
}

input:not([type]):focus:not([readonly]) + label,
input[type='text']:not(.browser-default):focus:not([readonly]) + label,
input[type='password']:not(.browser-default):focus:not([readonly]) + label,
input[type='email']:not(.browser-default):focus:not([readonly]) + label,
input[type='url']:not(.browser-default):focus:not([readonly]) + label,
input[type='time']:not(.browser-default):focus:not([readonly]) + label,
input[type='date']:not(.browser-default):focus:not([readonly]) + label,
input[type='datetime']:not(.browser-default):focus:not([readonly]) + label,
input[type='datetime-local']:not(.browser-default):focus:not([readonly])
	+ label,
input[type='tel']:not(.browser-default):focus:not([readonly]) + label,
input[type='number']:not(.browser-default):focus:not([readonly]) + label,
input[type='search']:not(.browser-default):focus:not([readonly]) + label,
textarea.materialize-textarea:focus:not([readonly]) + label {
	color: var(--brand) !important;
}

input:not([type]):focus:not([readonly]) + label,
input[type='text']:not(.browser-default):focus:not([readonly]) + label,
input[type='password']:not(.browser-default):focus:not([readonly]) + label,
input[type='email']:not(.browser-default):focus:not([readonly]) + label,
input[type='url']:not(.browser-default):focus:not([readonly]) + label,
input[type='time']:not(.browser-default):focus:not([readonly]) + label,
input[type='date']:not(.browser-default):focus:not([readonly]) + label,
input[type='datetime']:not(.browser-default):focus:not([readonly]) + label,
input[type='datetime-local']:not(.browser-default):focus:not([readonly])
	+ label,
input[type='tel']:not(.browser-default):focus:not([readonly]) + label,
input[type='number']:not(.browser-default):focus:not([readonly]) + label,
input[type='search']:not(.browser-default):focus:not([readonly]) + label,
textarea.materialize-textarea:focus:not([readonly]) + label {
	color: var(--brand) !important;
}

[type='checkbox'].filled-in:checked + span:not(.lever):after {
	top: 0;
	width: 20px;
	height: 20px;
	border: 2px solid var(--brand) !important;
	background-color: var(--brand) !important;
	z-index: 0;
}

i.material-icons.prefix.active {
	color: var(--brand);
}
/*#endregion*/
